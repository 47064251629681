<template>
  <div class="contact mainBox">
    <div class="contact-left">
      <div class="tit">{{ $t('main_contact_1') }}</div>
      <el-form
        :model="ruleForm"
        :hide-required-asterisk="true"
        label-position="top"
        size="small"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item
          :label="$t('咨询类型：')"
          prop="contact_type"
          :rules="[
            {
              required: true,
              message: $t('请选择咨询类型'),
              trigger: 'change',
            },
          ]"
        >
          <el-select
            v-model="ruleForm.contact_type"
            :placeholder="$t('请选择咨询类型')"
          >
            <el-option label="销售" value="1"></el-option>
            <el-option label="支持" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('main_contact_2')"
          prop="username"
          :rules="[
            { required: true, message: $t('main_contact_3'), trigger: 'blur' },
          ]"
        >
          <el-input
            :placeholder="$t('main_contact_4')"
            v-model="ruleForm.username"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('main_contact_5')"
          :rules="[
            { required: true, message: $t('main_contact_6'), trigger: 'blur' },
          ]"
          prop="surname"
        >
          <el-input
            v-model="ruleForm.surname"
            :placeholder="$t('main_contact_7')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('main_contact_8')">
          <el-input
            v-model="ruleForm.corporate_name"
            :placeholder="$t('main_contact_10')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('main_contact_11')"
          :rules="[
            {
              required: true,
              message: $t('main_contact_12'),
              trigger: 'blur',
            },
            {
              type: 'email',
              message: $t('reg_email_8'),
              trigger: ['blur'],
            },
          ]"
          prop="email"
        >
          <el-input
            v-model="ruleForm.email"
            :placeholder="$t('main_contact_13')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('main_contact_14')"
          :rules="[
            {
              required: true,
              message: $t('main_contact_15'),
              trigger: 'blur',
            },
          ]"
          prop="problem"
        >
          <el-input
            type="textarea"
            :rows="4"
            :placeholder="$t('main_contact_15')"
            v-model="ruleForm.problem"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('上传附件：')">
          <el-upload
            class="upload-demo"
            ref="uploadimg"
            action="#"
            accept="image/jpg,image/jpeg,image/png"
            :http-request="handleRequest"
            :before-remove="beforeRemove"
            :before-upload="handleBeforeUpload"
            multiple
            :limit="10"
            :file-list="fileList"
          >
            <el-button size="small" icon="el-icon-upload2" type="primary">{{
              $t('reg_bankInfo_5')
            }}</el-button>
            <div slot="tip" class="el-upload__tip">
              {{ $t('reg_bankInfo_6') }}：PNG, JPEG, PDF
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="">
          <el-button class="form-login" type="primary" @click="submitForm">
            {{ $t('main_contact_16') }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- <div class="contact-right">
      <img src="@/assets/img/home/contact.png" />
      <div class="cont">
        <div class="text">欢迎联系我们</div>
        <div class="text-tip">WELCOME TO CONTACT US</div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      ruleForm: {
        username: '',
        surname: '',
        corporate_name: '',
        email: '',
        problem: '',
      },
      isAgreement: false,
      fileList: [],
    }
  },
  mounted() {},
  methods: {
    toReg() {
      this.$router.push({ name: 'Email' })
    },
    submitLogin() {
      console.log('1')
    },
    // 判断上传的是否为图片
    handleBeforeUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf('.') + 1)
      const suffix = img === 'jpg'
      const suffix2 = img === 'png'
      const suffix3 = img === 'jpeg'
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!suffix && !suffix2 && !suffix3) {
        this.$message.error('只能上传图片！')
        return false
      }
      // 限制图片的大小
      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!')
        return false
      }
      return suffix || suffix2 || suffix3
    },
    // 文件上传
    async handleRequest(param) {
      let formData = new FormData()
      formData.append('file', param.file)
      let res = await this.$axios.post('/api/Common/upload', formData)
      if (res.code === 1) {
        let row = {
          uid: param.file.uid,
          name: param.file.name,
          url: res.data.fullurl,
        }
        this.fileList.push(row)
      }
    },
    beforeRemove(file) {
      let flag = false
      this.$confirm('您确定要移除此文件吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          flag = true
          for (let i = 0; i < this.fileList.length; i++) {
            if (this.fileList[i].uid === file.uid) {
              this.fileList.splice(i, 1)
              return
            }
          }
        })
        .catch(() => {})
      return flag
    },
    submitForm() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.ruleForm.contactimage = this.fileList
          this.nextLoading = true
          let res = await this.$axios.post(
            '/api/Contact/contact',
            this.ruleForm
          )
          this.nextLoading = false
          if (res.code === 1) {
            this.showMask = false
            this.$message({ type: 'success', message: 'submit SUCCESS' })
            this.ruleForm = {}
            this.$nextTick(() => {
              this.$refs.ruleForm.clearValidate()
              this.fileList = []
            })
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.contact {
  padding-top: 40px;
  padding-bottom: 40px;
}

.contact-left {
  width: 500px;
  margin: 0 auto;

  .tit {
    font-size: 14px;
    font-weight: 800;
    color: #333333;
    margin-bottom: 10px;
  }

  /deep/ .el-form {
    .el-form-item__label {
      line-height: 24px;
    }
    .el-select {
      width: 100%;
    }

    .el-textarea__inner {
      background: #f6f6f6;
    }

    .el-input__inner {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      background: #f6f6f6;
    }
  }
}

.contact-right {
  position: relative;

  img {
    width: 650px;
  }

  .cont {
    position: absolute;
    top: 100px;
    left: 200px;
    text-align: right;
    color: #ffffff;
    .text {
      font-size: 60px;
    }
    .text-tip {
      font-size: 18px;
      margin-top: 8px;
    }

    .form-contact {
      border-color: #ffffff;
      color: #ffffff;
      background-color: initial;
      margin-top: 40px;
    }
  }
}
</style>
