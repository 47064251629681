var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact mainBox"},[_c('div',{staticClass:"contact-left"},[_c('div',{staticClass:"tit"},[_vm._v(_vm._s(_vm.$t('main_contact_1')))]),_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"hide-required-asterisk":true,"label-position":"top","size":"small"}},[_c('el-form-item',{attrs:{"label":_vm.$t('咨询类型：'),"prop":"contact_type","rules":[
          {
            required: true,
            message: _vm.$t('请选择咨询类型'),
            trigger: 'change',
          } ]}},[_c('el-select',{attrs:{"placeholder":_vm.$t('请选择咨询类型')},model:{value:(_vm.ruleForm.contact_type),callback:function ($$v) {_vm.$set(_vm.ruleForm, "contact_type", $$v)},expression:"ruleForm.contact_type"}},[_c('el-option',{attrs:{"label":"销售","value":"1"}}),_c('el-option',{attrs:{"label":"支持","value":"2"}})],1)],1),_c('el-form-item',{attrs:{"label":_vm.$t('main_contact_2'),"prop":"username","rules":[
          { required: true, message: _vm.$t('main_contact_3'), trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":_vm.$t('main_contact_4')},model:{value:(_vm.ruleForm.username),callback:function ($$v) {_vm.$set(_vm.ruleForm, "username", $$v)},expression:"ruleForm.username"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('main_contact_5'),"rules":[
          { required: true, message: _vm.$t('main_contact_6'), trigger: 'blur' } ],"prop":"surname"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('main_contact_7')},model:{value:(_vm.ruleForm.surname),callback:function ($$v) {_vm.$set(_vm.ruleForm, "surname", $$v)},expression:"ruleForm.surname"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('main_contact_8')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('main_contact_10')},model:{value:(_vm.ruleForm.corporate_name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "corporate_name", $$v)},expression:"ruleForm.corporate_name"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('main_contact_11'),"rules":[
          {
            required: true,
            message: _vm.$t('main_contact_12'),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: _vm.$t('reg_email_8'),
            trigger: ['blur'],
          } ],"prop":"email"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('main_contact_13')},model:{value:(_vm.ruleForm.email),callback:function ($$v) {_vm.$set(_vm.ruleForm, "email", $$v)},expression:"ruleForm.email"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('main_contact_14'),"rules":[
          {
            required: true,
            message: _vm.$t('main_contact_15'),
            trigger: 'blur',
          } ],"prop":"problem"}},[_c('el-input',{attrs:{"type":"textarea","rows":4,"placeholder":_vm.$t('main_contact_15')},model:{value:(_vm.ruleForm.problem),callback:function ($$v) {_vm.$set(_vm.ruleForm, "problem", $$v)},expression:"ruleForm.problem"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('上传附件：')}},[_c('el-upload',{ref:"uploadimg",staticClass:"upload-demo",attrs:{"action":"#","accept":"image/jpg,image/jpeg,image/png","http-request":_vm.handleRequest,"before-remove":_vm.beforeRemove,"before-upload":_vm.handleBeforeUpload,"multiple":"","limit":10,"file-list":_vm.fileList}},[_c('el-button',{attrs:{"size":"small","icon":"el-icon-upload2","type":"primary"}},[_vm._v(_vm._s(_vm.$t('reg_bankInfo_5')))]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" "+_vm._s(_vm.$t('reg_bankInfo_6'))+"：PNG, JPEG, PDF ")])],1)],1),_c('el-form-item',{attrs:{"label":""}},[_c('el-button',{staticClass:"form-login",attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.$t('main_contact_16'))+" ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }